import { ResolverWindowElementFormOptions } from '@newageerp/crm-ui'
import React, { Fragment } from 'react'
import JsonField from './Components/JsonField'

export default function ResolverViewFormCustomField(props: ResolverWindowElementFormOptions) {
  return (
    <Fragment>
      {props.name === 'json/JsonField' && <JsonField fieldKey={props.fieldKey} />}
    </Fragment>)
}
