import React, { Fragment } from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import ReactJson from 'react-json-view';

interface Props {
    fieldKey: string,
}

export default function JsonField(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    if (!element) {
        return <Fragment />
    }
    
    const val = element[props.fieldKey];

    return (
        <div>
            <ReactJson 
                src={val} 
                name={false} 
            />
        </div>
    )
}
